import React from 'react';
import { Box, Grommet, Heading, Anchor } from 'grommet';
import { Header } from 'grommet-controls';

const TopHeader = (props) => (
    
    <Header
      position='sticky'
    >
      <Box direction='row' gap='small' align='center'>
        
        
          <Heading level={3} margin='none'>
            ITP/IMA Equitable Syllabus Project
          </Heading>
        
      </Box>
        <Anchor href='#' label='[LOGO]' size='large'/>
    </Header>
    

);

export default TopHeader; 