import React from 'react';
import { Box, Grommet } from 'grommet';
import { Sidebar, VerticalMenu } from 'grommet-controls';

// https://grommet-nextjs.herokuapp.com/documentation/grommet-controls/Sidebar

const LeftNav = (props) => (
  <Sidebar
    title='Menu'
    // border={{ size: 'medium' }}
    width='small'
    basis='small'
    collapsible={true}
    background='#000'
    justifyContent='center'
    height='large'
    
  >
    <Box overflow='auto'>
      <VerticalMenu
        // activeItem={{ id: 'archive' }}
        items={[
          {
           id: 'home',
            href: '/',
            label: 'Home',
            // icon: <Home />,
          },
          {
            id: 'about',
            href: '/#/about',
            label: 'About This Project',
            // icon: <Inbox />,
          },
          {
            id: 'faq',
            href: '/#/faq',
            label: 'FAQs',
            // icon: <UserSettings />,
          },
          {
            id: 'archive',
            href: '/#/archive',
            label: 'Archive',
            // icon: <UserSettings />,
          },
          {
            id: 'service',
            href: '/#/service',
            label: 'Research Service',
            // icon: <UserSettings />,
          },
          {
            id: 'featured',
            label: 'Featured Collections',
            // icon: <Dashboard />,
            // href: '/featured',
            items: [
              {
                id: 'creative_coding',
                href: '/#/featured',
                label: 'Creative Coding',
                // widget: <LineChart />,
              },
              {
                id: 'visual_language',
                href: '/#/featured',
                label: 'Visual Language',
                // widget: <BarChart  />,
              },
            ],
          },
        ]}
      />
      </Box>
    </Sidebar>
);

export default LeftNav;