import React from 'react';
import { Box, Grommet, ResponsiveContext } from 'grommet';
import { PagingTable } from 'grommet-controls';
import LeftNav from '../components/LeftNav'
import TopHeader from '../components/TopHeader'
import Theme from '../components/Theme'

// import firebase from 'firebase';
import firebase from "firebase/app";
import "firebase/firestore";

firebase.initializeApp({
    apiKey: "AIzaSyBUFvJBL7jbSmcB3JuXXKoZYEXxspCMHZY",
    authDomain: "itp-ima-library-project.firebaseapp.com",
    projectId: "itp-ima-library-project",
    storageBucket: "itp-ima-library-project.appspot.com",
    messagingSenderId: "173464126451",
    appId: "1:173464126451:web:fc0273623418e81c44c886",
    measurementId: "G-JZE33XYTYZ"
  });


let db = firebase.firestore();
let fs_data = []


// https://firebase.google.com/docs/firestore/query-data/get-data

db.collection("main").get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {

        let docData = doc.data(); 
        fs_data.push(docData); 
        // console.log(doc.id, " => ", doc.data());
    });
});


// can we protect our API keys in a react app using dotenv: https://stackoverflow.com/questions/42182577/is-it-possible-to-use-dotenv-in-a-react-project


const SampleTable = () => (

  <PagingTable
    decorations={{      
     row: { hover: true }
    }}
    columns={[
          
          {
            Header: 'Researcher',
            accessor: 'researcher',
          }, {
            Header: 'Course',
            accessor: 'course',
          }, {
            Header: 'Resource',
            accessor: 'resource',
          }, {
            Header: 'Resource Creator',
            accessor: 'resource_creator',
          }, {
            Header: 'Professor',
            accessor: 'professor',
          },


          {
            Header: 'Attribution',
            accessor: 'attribution',
          }, {
            Header: 'Author Link',
            accessor: 'author_link',
          }, {
            Header: 'Creator Role',
            accessor: 'creator_role',
          }, {
            Header: 'Data Updated',
            accessor: 'data_updated',
          },

          {
            Header: 'Format',
            accessor: 'format',
          }, {
            Header: 'Keywords',
            accessor: 'keywords',
          }, {
            Header: 'Link',
            Cell: props => (
              props.original.link
            ),
          }, {
            Header: 'Related Disciplines',
            accessor: 'related_disciplines',
          },

          {
            Header: 'Related Geographies',
            accessor: 'related_geographies',
          },
      ]}

    data={fs_data}
  />
);
 
const Archive = () => {
  return (
      <Grommet theme={Theme}>
      <ResponsiveContext.Consumer>

        {responsiveSize => (

          <>
          <Box>
              <TopHeader/>
          <Box>

              <Box direction='row'>
                <LeftNav />

                  <Box flex gap='large' fill='horizontal' pad='large'>

                    {/* This is where our main body content goes for each page */}
                    <SampleTable />

                  </Box>

              </Box>

          </Box>
          </Box>
          </>
        )}

      </ResponsiveContext.Consumer>
      </Grommet>

  );
};

export default Archive;