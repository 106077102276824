import React, { Component } from "react";

import MainPage from "./pages";
import Archive from "./pages/Archive";
import AboutUs from "./pages/AboutUs";
import FAQ from "./pages/FAQ";
import FeaturedCollections from "./pages/FeaturedCollections";
import ResearchService from "./pages/ResearchService";


import {HashRouter as Router, Route, Switch } from "react-router-dom";

// router example: https://medium.com/@ipenywis/intro-to-react-router-for-beginners-multiple-page-apps-461f4729bd3f
// router debuggin: https://stackoverflow.com/questions/41956465/how-to-create-multiple-page-app-using-react

class App extends Component {
  render() {
    return (
      <Router>
      <Switch>
       {/*All our Routes goes here!*/}
       <Route exact path="/" component={MainPage} />
       <Route exact path="/archive" component={Archive} />
       <Route exact path="/about" component={AboutUs} />
       <Route exact path="/faq" component={FAQ} />
       <Route exact path="/featured" component={FeaturedCollections} />
       <Route exact path="/service" component={ResearchService} />
       
       </Switch>
      </Router>
    );
  }
}

export default App;


