import React from 'react';
// import { Box, Grommet, Text, ResponsiveContext } from 'grommet';
import LeftNav from '../components/LeftNav'
import TopHeader from '../components/TopHeader'
// import Theme from '../components/Theme'


const AboutUS = () => {


  return (

    // fragment
    <> 
    
    <h1>About the Project!</h1>
    
    <p> The mission of the [Equitable Syllabus Project] is to encourage critical interrogation of the voices we amplify in our curricula. We offer our students and faculty tools and frameworks to thoughtfully re-engage their course material with an equitable lens. We are a departmental program that seeks to assist full-time and adjunct faculty in diversifying and expanding the voices included in their syllabi. We achieve this aim by two means:
      (1) a research service conducted by research assistants that work 1:1 with faculty members to identify and fill in representational gaps in course material, and
      (2) compiling, developing and maintaining a growing archive of relevant course materials that centers work from Black, Brown, and Indigenous peoples, as well as other historically underrepresented groups.

    </p>
    
    </>


  


  );
};

export default AboutUS;


/*

{ <Grommet theme={Theme}>
<ResponsiveContext.Consumer>

  {responsiveSize => (
    <>
    <Box>
        <TopHeader/>
    <Box>

        <Box direction='row'>
          <LeftNav />

            <Box flex gap='large' fill='horizontal' pad='large'>

               
              <Text>About the Project!</Text>

              <Text>
              
              The mission of the [Equitable Syllabus Project] is to encourage critical interrogation of the voices we amplify in our curricula. We offer our students and faculty tools and frameworks to thoughtfully re-engage their course material with an equitable lens. 

              We are a departmental program that seeks to assist full-time and adjunct faculty in diversifying and expanding the voices included in their syllabi. We achieve this aim by two means: 
              (1) a research service conducted by research assistants that work 1:1 with faculty members to identify and fill in representational gaps in course material, and  
              (2) compiling, developing and maintaining a growing archive of relevant course materials that centers work from Black, Brown, and Indigenous peoples, as well as other historically underrepresented groups. 
              
              </Text>
              

            </Box>
            
        </Box>

    </Box>
    </Box>
    </>
  )}

</ResponsiveContext.Consumer>
</Grommet> } 

*/ 