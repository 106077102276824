import React from 'react';
import { Box, Grommet, Text, ResponsiveContext, } from 'grommet';
import LeftNav from '../components/LeftNav'
import TopHeader from '../components/TopHeader'
import Theme from '../components/Theme'

 
const MainPage = () => {
  return (
      <Grommet theme={Theme}>
      <ResponsiveContext.Consumer>

        {responsiveSize => (
          <>
          <Box>
              <TopHeader/>
          <Box>

              <Box direction='row'>
                <LeftNav />

                  <Box flex gap='large' fill='horizontal' pad='large'>

                    {/* This is where our main body content goes for each page */}
                    <Text>Main/Home -- should greet visitors, soft intro</Text>

                  </Box>
                  
              </Box>

          </Box>
          </Box>
          </>
        )}

      </ResponsiveContext.Consumer>
      </Grommet>

  );
};

export default MainPage;