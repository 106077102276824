import React from 'react';
import { Box, Grommet, Text, Paragraph, Anchor, ResponsiveContext } from 'grommet';
import LeftNav from '../components/LeftNav'
import TopHeader from '../components/TopHeader'
import Theme from '../components/Theme'


const Index = () => (
    <Box align="left" pad="medium">
      <Anchor href="#">General</Anchor>
      <Anchor href="#">Policies</Anchor>
      <Anchor href="#">Processes</Anchor>
      <Anchor href="#">Column Glossary</Anchor>
    </Box>
);

const QuestionGroup = () => (

  <>

  <Text>General</Text>

  <Text>1. What is the ITP/IMA Equitable Syllabus Project?</Text>

  <Paragraph>Answer</Paragraph>

  </>


);

const FAQ = () => {
  return (
      <Grommet theme={Theme}>
      <ResponsiveContext.Consumer>

        {responsiveSize => (
          <>
          <Box>
              <TopHeader/>
          <Box>

              <Box direction='row'>
                <LeftNav />

                  <Box flex gap='large' fill='horizontal' pad='large'>

                    {/* This is where our main body content goes for each page */}
                    <Text>FAQs</Text>
                    <Index />
                    
                    <QuestionGroup />
                    <QuestionGroup />
                    <QuestionGroup />
                    <QuestionGroup />

                    

                  </Box>
                  
              </Box>

          </Box>
          </Box>
          </>
        )}

      </ResponsiveContext.Consumer>
      </Grommet>

  );
};

export default FAQ;