import React from 'react';
import { Box, Grommet, Grid, Text, ResponsiveContext } from 'grommet';
import { Card } from 'grommet-controls';
import LeftNav from '../components/LeftNav'
import TopHeader from '../components/TopHeader'
import Theme from '../components/Theme'


const FeaturedCard = () => (

  <Card pad="small" background="transparent">

    <Card.CardTitle border="bottom" pad="medium" > COLLECTION TITLE</Card.CardTitle>
    <Card.CardContent basis="small">Some description, a little fun, maybe an image, who knows.</Card.CardContent>

    {/* onclick action to be added */}

  </Card>

);


const FeaturedCollections = () => {
  return (
      <Grommet theme={Theme}>
      <ResponsiveContext.Consumer>

        {responsiveSize => (
          <>
          <Box>
              <TopHeader/>
          <Box>

              <Box direction='row'>
                <LeftNav />

                  <Box flex gap='large' fill='horizontal' pad='large'>

                    {/* This is where our main body content goes for each page */}

                    <Text>Featured Collections</Text>

                    <Grid
                      gap="large"
                      rows="medium"
                      columns={{ count: 'fit', size: ['small', 'medium'] }}
                    >
                    

                    <FeaturedCard />
                    <FeaturedCard />
                    <FeaturedCard />

                    </Grid>


                  </Box>
                  
              </Box>

          </Box>
          </Box>
          </>
        )}

      </ResponsiveContext.Consumer>
      </Grommet>
  );
};

export default FeaturedCollections;
