const Theme = {
    global: {
        // box-shadow: none,
      colors: {
       brand: '#000',
       //background: '#EFC5BC',
       background: '#000',
     },
     // background: '#EFC5BC',
      font: {
        family: 'Source Code Pro',
        size: '10px',
        height: '14px',
      },
      button: {
        background: '#000',
      },
    },
  };

  export default Theme;